@import url(https://fonts.googleapis.com/css?family=Ubuntu);


.registration {
    margin: 90px 40px 0 40px;
}

.registration p {
    font-size: 20px;
    padding: 0 15px;
}

.registration h2 {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
}

.registration form {
    border: 1px solid black;
    padding: 20px;
}

.registration .form-element {
    padding: 4px 0;
    font-size: 18px;
}

.registration .form-button {
    text-align: center;
    margin: 10px 0 6px 0;
}

.registration .button {
    font-size: 24px;
    padding: 5px;
    background-color: white;
    border: 1px solid black;
}

.registration .form-link {
    text-align: center;

}


.login {
    margin: 90px 40px 0 40px;
}

.login h2 {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
}

.login form {
    border: 1px solid black;
    padding: 20px;
}

.login .form-element {
    padding: 4px 0;
    font-size: 18px;
}

.login .form-button {
    text-align: center;
    margin: 20px 0 6px 0;
}

.login .button {
    font-size: 24px;
    padding: 5px;
    background-color: white;
    border: 1px solid black;
}




.dashBoard {
    margin: 50px 40px 100px 40px;
}

.dashBoard h2 {
    text-align: left;
    font-size: 32px;
    line-height: 36px;
}

.dashBoard .button {
    font-size: 24px;
    padding: 5px;
    background-color: white;
    border: 1px solid black;
    text-decoration: none;
    color: black;
}

.dashBoard .subheader {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.dashBoard .totalScore {
    text-align: center;
    margin: 0;
}

.wordsList {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.dashBoard .totalScore,
.words p,
.corrects p,
.incorrects p {
    font-size: 16px;
}

.wordsList .correct,
.wordsList .incorrect {
    text-align: center;
}

.wordsList .words,
.wordsList .corrects,
.wordsList .incorrects {
    font-size: 14px;
}

@media (min-width: 350px) {
    .dashBoard .totalScore,
    .words p,
    .corrects p,
    .incorrects p {
        font-size: 20px;
}

    .wordsList .words,
    .wordsList .corrects,
    .wordsList .incorrects {
        font-size: 16px;
}

}

.learning {
    margin: 50px 20px 100px 20px;
}

.learning .totalscore {
    text-align: right;
}

.learning h2 {
    text-align: center;
    font-size: 32px;
}

.learning h3 {
    font-size: 32px;
}

.learning .question-title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.learning .score {
    font-size: 24px;
    font-weight: bolder;
}
.learning .correct {
    color: #8ECE3A;
}

.learning .incorrect {
    color: #CE3A44;
}

.learning .answers {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.learning .answer {
    margin: 10px 10px;
    padding: 20px;
    text-align: center;
    width: 300px;
    font-size: 24px;
}

.learning .selected {
    background-color: #3ACEC4;
}

.learning .button {
    text-align: center;
}

.learning button {
    margin: 10px 10px;
    padding: 20px;
    font-size: 24px;
}

.learning .response{
    text-align: center;
}

.learning .response p{
    font-size: 24px;
}

.toggle-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 30px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.drawer-toggle-button:focus {
    outline: none;
}


.drawer-toggle-button .fa-bars {
    color: darkslategrey;
}


.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #3ACEC4;
    height: 56px;
    z-index: 50;
  }
  
  .navigation {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    padding: 0 1rem 0 1rem;
  }
  
  .logo {
    margin-left: 1rem;
  }
  
  .logo a{
    color: darkslategrey;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bolder;
  }
  
  .spacer {
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  
  .navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
  }
  
  .navigation-items li {
    padding: 0 0.5rem;
  }
  
  .navigation-items a {
    color: #F2CD60;
    text-decoration: none;
  }
  
  .navigation-items .menu:hover,
  .navigation-items .menu:active {
    color: 
  }
  
  @media (max-width: 700px) {
    .navigation-items {
      display: none;
    }
  }
  
  @media (min-width: 701px) {
  
    .toggle-button {
      display: none;
    }
  
    .logo {
      margin-left: 0;
    }
  }
.side-drawer {
    height: 100%;
    background: #373a47;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.6s ease-out;
    transition: -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
    transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}

.side-drawer.is-open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 2rem 0 0 0;
}

.side-drawer li, .side-drawer span {
    margin: 0.7rem 0 0.7rem 2rem;
}

.side-drawer .menu {
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: red;
}


@mdedia (min-width: 700px) {
    .side-drawer {
        display: none;
    }
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6); 
    z-index: 100;
    margin: 0;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

