
.registration {
    margin: 90px 40px 0 40px;
}

.registration p {
    font-size: 20px;
    padding: 0 15px;
}

.registration h2 {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
}

.registration form {
    border: 1px solid black;
    padding: 20px;
}

.registration .form-element {
    padding: 4px 0;
    font-size: 18px;
}

.registration .form-button {
    text-align: center;
    margin: 10px 0 6px 0;
}

.registration .button {
    font-size: 24px;
    padding: 5px;
    background-color: white;
    border: 1px solid black;
}

.registration .form-link {
    text-align: center;

}
