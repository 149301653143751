
.dashBoard {
    margin: 50px 40px 100px 40px;
}

.dashBoard h2 {
    text-align: left;
    font-size: 32px;
    line-height: 36px;
}

.dashBoard .button {
    font-size: 24px;
    padding: 5px;
    background-color: white;
    border: 1px solid black;
    text-decoration: none;
    color: black;
}

.dashBoard .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.dashBoard .totalScore {
    text-align: center;
    margin: 0;
}

.wordsList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.dashBoard .totalScore,
.words p,
.corrects p,
.incorrects p {
    font-size: 16px;
}

.wordsList .correct,
.wordsList .incorrect {
    text-align: center;
}

.wordsList .words,
.wordsList .corrects,
.wordsList .incorrects {
    font-size: 14px;
}

@media (min-width: 350px) {
    .dashBoard .totalScore,
    .words p,
    .corrects p,
    .incorrects p {
        font-size: 20px;
}

    .wordsList .words,
    .wordsList .corrects,
    .wordsList .incorrects {
        font-size: 16px;
}

}