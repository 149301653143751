
.learning {
    margin: 50px 20px 100px 20px;
}

.learning .totalscore {
    text-align: right;
}

.learning h2 {
    text-align: center;
    font-size: 32px;
}

.learning h3 {
    font-size: 32px;
}

.learning .question-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.learning .score {
    font-size: 24px;
    font-weight: bolder;
}
.learning .correct {
    color: #8ECE3A;
}

.learning .incorrect {
    color: #CE3A44;
}

.learning .answers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.learning .answer {
    margin: 10px 10px;
    padding: 20px;
    text-align: center;
    width: 300px;
    font-size: 24px;
}

.learning .selected {
    background-color: #3ACEC4;
}

.learning .button {
    text-align: center;
}

.learning button {
    margin: 10px 10px;
    padding: 20px;
    font-size: 24px;
}

.learning .response{
    text-align: center;
}

.learning .response p{
    font-size: 24px;
}