
.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.drawer-toggle-button:focus {
    outline: none;
}


.drawer-toggle-button .fa-bars {
    color: darkslategrey;
}

